import styled from 'styled-components'

import { Container } from 'components/common/Grid'

const Section = styled(Container)`
  padding-top: 45px;
  padding-bottom: 100px;
`

export default Section
