import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'

const TextAreaStyled = styled.textarea`
	width: 100%;
	margin: 25px 0 50px 0;
	padding: 20px;

	line-height: 24px;

	border-radius: 10px;
	border: solid 1px ${({ theme, error }) => (error ? `${theme.colors.error}` : 'transparent')};
	box-shadow: 1px 1px 40px 0 rgba(0, 0, 0, 0.02);
	background-color: ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.gray};

	overflow: hidden;
	resize: none;

	&:focus {
		outline: none;
		border: solid 1px ${({ theme }) => theme.colors.primary};
	}
`

const TextArea = ({ id, error, formik }) => {
	const lh = 24 // line height
	const [rows, set_rows] = React.useState(1)

	const textareaRef = React.useRef()

	React.useEffect(() => {
		resize(0)
	}, [])

	const resize = (init = 0) => set_rows(~~((textareaRef.current.scrollHeight - 38 + init) / lh))

	const handleChange = e => {
		resize()
		formik.setFieldValue(id, e.target.value)
	}

	return (
		<TextAreaStyled
			id={id}
			name={id}
			ref={textareaRef}
			rows={rows}
			onChange={handleChange}
			error={error}
			value={formik.values[id]}
		/>
	)
}

export default TextArea
