import React from 'react'
import styled from 'styled-components'
import { isObject } from 'lodash'

import plusCircleSvg from 'assets/svg/plus-circle.svg'

const SingleImageStyled = styled.div`
  margin-bottom: 50px;

  input[type='file'] {
    /* Hide default input file input */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 150px;
  width: 150px;
  margin-top: 25px;

  border-radius: 10px;
  border: ${({ theme, error }) =>
    error ? `solid 1px ${theme.colors.error}` : 'none'};
  box-shadow: 1px 1px 40px 0 rgba(0, 0, 0, 0.02);
  background-color: ${({ theme }) => theme.colors.white};
  user-select: none;
  overflow: hidden;

  img {
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
  }

  span {
    position: absolute;
    top: 5px;
    right: 35px;
    visibility: hidden;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;

      background-image: url(${plusCircleSvg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0 0;
      transform: rotate(45deg);
    }
  }

  &:hover span {
    visibility: visible;
  }

  label {
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: -20px;
      left: -20px;
      width: 40px;
      height: 40px;

      background-image: url(${plusCircleSvg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0 0;
    }
  }
`

const SingleImage = ({ id, error, formik }) => {
  const updateImage = image => formik.setFieldValue(id, image)

  const addImage = e => {
    e.persist()
    updateImage(e.target.files[0])
  }

  const deleteImage = () => updateImage()

  const file = formik.values[id]
  const imgSrc = isObject(file) ?  URL.createObjectURL(file) : file

  return (
    <SingleImageStyled>
      <ImageBox error={error}>
        {file ? (
          <>
            <img src={imgSrc} alt={file.name} />
            <span onClick={deleteImage} />
          </>
        ) : (
          <label htmlFor={id} />
        )}
      </ImageBox>

      <input
        type='file'
        id={id}
        name={id}
        hidden
        accept='image/*'
        onChange={addImage}
      />
    </SingleImageStyled>
  )
}

export default SingleImage
