import React from 'react'
import styled from 'styled-components'

import { Container, Row, Column } from 'components/common/Grid'

import plusCircleSvg from 'assets/svg/plus-circle.svg'

const ImagesInputStyled = styled(Container)`
  margin-bottom: 50px;

  input[type='file'] {
    /* Hide default input file input */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 150px;
  width: 100%;
  margin-top: 25px;

  border-radius: 10px;
  border: ${({ theme, error }) =>
    error ? `solid 1px ${theme.colors.error}` : 'none'};
  box-shadow: 1px 1px 40px 0 rgba(0, 0, 0, 0.02);
  background-color: ${({ theme }) => theme.colors.white};
  user-select: none;
  overflow: hidden;

  img {
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
  }

  span {
    position: absolute;
    top: 5px;
    right: 35px;
    visibility: hidden;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;

      background-image: url(${plusCircleSvg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0 0;
      transform: rotate(45deg);
    }
  }

  &:hover span {
    visibility: visible;
  }

  label {
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: -20px;
      left: -20px;
      width: 40px;
      height: 40px;

      background-image: url(${plusCircleSvg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0 0;
    }
  }
`

const ImagePreview = ({ file, deleteImage }) => {
  const imgSrc = URL.createObjectURL(file)

  return (
    <ImageBox>
      <img src={imgSrc} alt={file.name} />
      <span onClick={deleteImage} />
    </ImageBox>
  )
}

const ImagesInput = ({ id, error, formik, inputRef }) => {
  const updateImages = images => formik.setFieldValue(id, images)

  const addImages = e => {
    e.persist()
    const old_images = formik.values[id]
    const new_images = Object.values(e.target.files)
    const all_images = [...old_images, ...new_images]
    updateImages(all_images)
  }

  const deleteImage = file => () => {
    const old_images = formik.values[id]
    const new_images = old_images.filter(({ name }) => name !== file.name)
    updateImages(new_images)
  }

  return (
    <ImagesInputStyled>
      <Row>
        {formik.values[id].map((file, i) => (
          <Column width={[1 / 2, 1 / 3]} px={[8]} key={file.name + '_' + i}>
            <ImagePreview file={file} deleteImage={deleteImage(file)} />
          </Column>
        ))}

        <Column width={[1 / 2, 1 / 3]} px={[8]}>
          <ImageBox error={error}>
            <label htmlFor={id} />
          </ImageBox>
        </Column>
      </Row>

      <input
        type='file'
        id={id}
        name={id}
        ref={inputRef}
        multiple
        hidden
        accept='image/*'
        onChange={addImages}
      />
    </ImagesInputStyled>
  )
}

export default ImagesInput
