import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { Formik } from 'formik'
import { useStore } from 'effector-react'
import isEmpty from 'lodash/isEmpty'

import useAuth from 'utils/hooks/useAuth'

import { 
  store,
  editUserName,
	initializeImageUpload,
	uploadImage,
  finalizeProfileImageUpload
} from 'components/common/GlobalState'
import { Row, Column } from 'components/common/Grid'
import Button from 'components/common/Button'
import BackLink from 'components/elements/BackLink'

import { Input, Label, Section, Title } from 'components/common/Form'

const PrivacyOptionsDescription = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 30px;
  margin-bottom: -10px;
`


export default function ProfileEdit() {
	const { profile: userDetails } = useStore(store)
  const { profile = {} } = useAuth()
  
	const profileImage = userDetails.profileUri || !isEmpty(profile) && profile.picture

  const initialValues = {
    username: userDetails.displayName,
    email: userDetails.email,
    image: profileImage
  }

  const validate = values => {
    let errors = {}

    errors.username = !values.username

    return Object.keys(errors)
      .filter(key => errors[key])
      .reduce((acc, key) => ((acc[key] = errors[key]), acc), {})
  }

  const onSubmit = async (values, { setSubmitting }) => {
    if(values.username && values.username !== userDetails.displayName) {
      editUserName({ name: values.username })
    }

    if(values.image && values.image !== profileImage) {
      const file = values.image
      const { data } = await initializeImageUpload({ fileName: file.name })
      const { fileUploadUri, uploadSessionGuid } = data
      await uploadImage({ fileUploadUri, file })
      await finalizeProfileImageUpload({ uploadSessionGuid })
    }

    setSubmitting(false)
    navigate('/app/')
  }

  const getError = (formik, name) => {
    const { errors, touched } = formik
    return errors[name] && touched[name] ? 'true' : undefined
  }

  return (
    <Section>
      <Row>
        <Column>
          <BackLink url='/app/' type='internal' title='Back' />
        </Column>
      </Row>

      <Row>
        <Column width={[1, 1 / 2]}>
          <Title>Edit Profile</Title>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
          >
            {formik => (
              <form onSubmit={formik.handleSubmit}>
                {/* Username */}
                <Label htmlFor='username'>Username</Label>
                <Input.Text
                  id='username'
                  error={getError(formik, 'username')}
                />

                {/* Email Address */}
                <Label htmlFor='email'>Email Address</Label>
                <Input.Text
                  disabled
                  id='email'
                  type='email'
                  error={getError(formik, 'email')}
                />

                {/* Profile Image */}
                <Label htmlFor='image'>Profile Image</Label>
                <Input.SingleImage
                  id='image'
                  formik={formik}
                  error={getError(formik, 'image')}
                />

                <Button primary type='submit' disabled={formik.isSubmitting}>
                  Save
                </Button>
              </form>
            )}
          </Formik>
        </Column>
      </Row>
    </Section>
  )
}
