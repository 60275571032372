import React from 'react'
import { navigate } from 'gatsby'
import { Formik } from 'formik'
import { useStore } from 'effector-react'
import useBeforeUnload from 'use-before-unload'

import {
	store,
	createResykable,
	initializeImageUpload,
	uploadImage,
	finalizeImageUpload
} from 'components/common/GlobalState'
import { Row, Column } from 'components/common/Grid'
import Button from 'components/common/Button'
import BackLink from 'components/elements/BackLink'

import Info from './Info'

import { Input, Label, Section, Title } from 'components/common/Form'

export default function ResykableCreate({ location }) {
	const { profile: userDetails } = useStore(store)
	const { state: showFileDialog } = location

	useBeforeUnload('Are you sure you want to exit?')

	const inputRef = React.useRef()

	React.useEffect(() => {
		setTimeout(() => {
			if (showFileDialog && inputRef.current) {
				inputRef.current.click()
			}
		}, 500)
	}, [showFileDialog])

	const initialValues = {
		title: '',
		description: '',
		images: []
		// purchaseDate: '',
		// purchasePrice: '',
		// purchasedFrom: '',
		// purchaseState: ''
	}

	const validate = values => {
		let errors = {}

		errors.title = !values.title
		errors.description = !values.description || values.description.length > 500

		return Object.keys(errors)
			.filter(key => errors[key])
			.reduce((acc, key) => ((acc[key] = errors[key]), acc), {})
	}

	const onSubmit = async (values, { setSubmitting }) => {
		try {
			setSubmitting(true)

			const resykable = await createResykable({
				title: values.title,
				description: values.description
				// purchaseDate: values.purchaseDate,
				// purchasePrice: values.purchasePrice.toString(),
				// purchasedFrom: values.purchasedFrom,
				// purchaseState: values.purchaseState
			})
			const resykableGuid = resykable.data.guid

			values.images.forEach(async file => {
				const { data } = await initializeImageUpload({ fileName: file.name })
				const { fileUploadUri, uploadSessionGuid } = data
				await uploadImage({ fileUploadUri, file })
				await finalizeImageUpload({ uploadSessionGuid, resykableGuid })
			})

			setSubmitting(false)
			navigate('/app/')
		} catch (error) {
			console.log({ error })
		}
	}

	const getError = (formik, name) => {
		const { errors, touched } = formik
		return errors[name] && touched[name] ? 'true' : undefined
	}

	return (
		<Section>
			<Row>
				<Column>
					<BackLink url='/app/' type='internal' title='Back' />
				</Column>
			</Row>

			<Row>
				<Column width={[1, 1, 1 / 2]}>
					<Title>Add a new Resykable</Title>
					<Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
						{formik => (
							<form onSubmit={formik.handleSubmit}>
								{/* Title */}
								<Label htmlFor='title'>Add A Title</Label>
								<Input.Text bold='true' id='title' error={getError(formik, 'title')} />

								{/* Description */}
								<Label htmlFor='description'>Add A Description</Label>
								<Input.TextArea
									id='description'
									formik={formik}
									error={getError(formik, 'description')}
								/>

								{/* Images */}
								<Label htmlFor='images'>Upload Images</Label>
								<Input.Images
									id='images'
									inputRef={inputRef}
									formik={formik}
									error={getError(formik, 'images')}
								/>

								{/* //// Purchase Date
								<Label htmlFor='purchaseDate'>
									Purchase Date <span>Optional</span>
								</Label>
								<Input.Date
									id='purchaseDate'
									formik={formik}
									error={getError(formik, 'purchaseDate')}
								/>

								//// Purchase Price
								<Label htmlFor='purchasePrice'>
									Purchase Price <span>Optional</span>
								</Label>
								<Input.Number
									id='purchasePrice'
									formik={formik}
									error={getError(formik, 'purchasePrice')}
									step='0.01'
								/>

								//// Purchased From
								<Label htmlFor='purchasedFrom'>
									Purchased From <span>Optional</span>
								</Label>
								<Input.Text
									id='purchasedFrom'
									formik={formik}
									error={getError(formik, 'purchasedFrom')}
								/>

								//// Purchase State
								<Label htmlFor='purchaseState'>
									Purchase State <span>Optional</span>
								</Label>
								<Input.Radio
									id='purchaseState'
									value={formik.values['purchaseState']}
									error={getError(formik, 'purchaseState')}
									options={['New', 'Used', 'Other']}
								/> */}

								<Button primary type='submit' disabled={formik.isSubmitting}>
									Add to My Registry
								</Button>
							</form>
						)}
					</Formik>
				</Column>

				<Column width={[1, 1 / 2]}>
					<Info userDetails={userDetails} />
				</Column>
			</Row>
		</Section>
	)
}
