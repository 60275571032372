import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { useStore } from 'effector-react'
import isEmpty from 'lodash/isEmpty'

import useAuth from 'utils/hooks/useAuth'
import { store } from 'components/common/GlobalState'
import { Container, Column, Row } from 'components/common/Grid'
import Button from 'components/common/Button'
import Link from 'components/elements/Link'

import media from 'utils/media'

import avatar from 'assets/svg/avatar.svg'
import envelopeSvg from 'assets/svg/envelope-blob.svg'
import linkSvg from 'assets/svg/link.svg'

const ContainerStyled = styled(Container)`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const ColumnStyled = styled(Column)`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const User = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	padding-bottom: 30px;
	margin: 30px 0 50px 0;
	border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
`

const Avatar = styled.img`
	height: 100px;
	width: 100px;
	border-radius: 50%;
	border: 3px solid ${({ theme }) => theme.colors.primary};
`

const Name = styled.h5`
	font-family: QuickSand;
	font-size: 21px;
	color: ${({ theme }) => theme.colors.primary};

	margin: 15px 0;
`

const Details = styled(Row)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: center;
	
	${media.md`
		text-align: left;
	`}

	div {
		display: flex;
		flex-direction: column;
	}

	h5 {
		font-size: 17px;
		font-family: QuickSand;
		color: ${({ theme }) => theme.colors.primary};
		margin: 10px 0;
	}

	a {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		font-size: 17px;
		font-weight: 500;
		color: ${({ theme }) => theme.colors.gray};

		${media.md`
			justify-content: flex-start;
		`}

		img {
			position: relative;
			width: 18px;
			height: 18px;
			margin-left: 10px;
			transform: translateY(-6px);
		}
	}
`

const ImageWrapper = styled(Column)`
	padding-right: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 80px;
	}
`

const EditProfileLink = styled(Link)`
	font-size: 17px;
  font-weight: 500;
	color: ${({theme})=>theme.colors.gray};
`

const ShareResykButton = styled(Button)`
	margin: 80px 0;
`

export default function Profile() {
	const { profile: userDetails } = useStore(store)
	const { isLoggedIn, profile = {} } = useAuth()
	const profileImage = userDetails.profileUri || !isEmpty(profile) && profile.picture

	const onShareResykClick = () => {
		console.log('Share Resyk');
		// is Web Share API supported?
		if (navigator.share) {
			navigator.share({
				url: window.location,
				title: 'Share Resyk',
				text: 'Share Resyk'
			})
		}
	}

	return (
		<ContainerStyled>
			<Row>
				<ColumnStyled>
					<User>
						<Avatar src={profileImage || avatar} alt={userDetails.displayName} />
						<Name>{userDetails.displayName}</Name>
						<EditProfileLink url='/app/profile/edit'  title='Edit Profile' type='internal' />
					</User>

					<Details>
						<ImageWrapper width={[1, 1, 1 / 4]}>
							<img src={envelopeSvg} alt='Envelope icon' />
						</ImageWrapper>
						<Column width={[1, 1, 3 / 4]}>
							<h5>Email Upload Address</h5>
							<a href={`mailto:${userDetails.resykEmail}`}>
								{userDetails.resykEmail}
								<img src={linkSvg} alt='link' />
							</a>
						</Column>
					</Details>

					<ShareResykButton primary type="button" onClick={onShareResykClick}>
						Share Resyk
					</ShareResykButton>
				</ColumnStyled>
			</Row>
		</ContainerStyled>
	)
}
