import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'

const SwitchStyled = styled.div`
  display: flex;
`

const Option = styled.div`
  font-size: 13px;
  font-weight: ${({ visible }) => visible && 'bold'};
  color: ${({ theme }) => theme.colors.gray};
  cursor: pointer;

  & + div {
    margin-left: 10px;
  }
`

const Switch = ({ id, formik }) => {
  const is_visible = formik.values[id]

  const show = () => formik.setFieldValue(id, true)
  const hide = () => formik.setFieldValue(id, false)

  return (
    <SwitchStyled>
      <Field id={id} type='checkbox' hidden />
      <Option onClick={show} visible={is_visible}>
        Show
      </Option>
      <Option onClick={hide} visible={!is_visible}>
        Hide
      </Option>
    </SwitchStyled>
  )
}

export default Switch
