import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import Button from 'components/common/Button'
import Modal, { ModalText } from 'components/common/Modal'

import envelopeSvg from 'assets/svg/envelope-blob.svg'
import xSvg from 'assets/svg/x.svg'

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  max-width: 360px;
`

const Image = styled.img`
  margin-top: 15px;
  width: 80px;
`

const ModalTextStyled = styled(ModalText)`
  margin-top: 30px;
  margin-bottom: 25px;

  font-family: QuickSand;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
`

const WelcomeModal = ({ isOpen, closeModal }) => {
  const goToRegistry = () => {
    closeModal()
    navigate('/app/')
  }

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <CloseButton type='button' onClick={closeModal}>
        <img src={xSvg} alt='close-button' />
      </CloseButton>

      <Image src={envelopeSvg} alt='Envelope icon' />
      <ModalTextStyled>
        Thanks for using the email uploader. If you received a confirmation
        email, your Resykable will now be in your Registry.
      </ModalTextStyled>
      <Button primary type='button' onClick={goToRegistry}>
        Go To Your Registry
      </Button>
    </Modal>
  )
}

export default WelcomeModal
