import React from 'react'
import styled from 'styled-components'

import TextField from './Text'

import plusCircleSvg from 'assets/svg/plus-circle.svg'

const DateField = styled(TextField)`
  -webkit-appearance: none;

  &::-webkit-inner-spin-button,
  &::-webkit-clear-button {
    display: none;
    -webkit-appearance: none;
  }

  &[value='']::-webkit-datetime-edit {
    color: transparent;
  }
  &:focus::-webkit-datetime-edit {
    color: ${({ theme }) => theme.colors.gray} !important;
  }

  &::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
  }
  &:hover::-webkit-calendar-picker-indicator {
    display: block;
    background: url(${plusCircleSvg}) no-repeat;
    width: 27px;
    height: 27px;
    cursor: pointer;
  }
`

const DateInput = props => <DateField type='date' name={props.id} {...props} />

export default DateInput
