import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'

const TextField = styled(Field)`
  width: 100%;
  height: 60px;
  margin: 25px 0 50px 0;
  padding: 20px;

  font-weight: ${({ bold }) => bold && `bold`};
  color: ${({ theme }) => theme.colors.gray};
  opacity: ${({ dimmed }) => dimmed && '0.5'};

  border-radius: 10px;
  border: solid 1px
    ${({ theme, error }) => (error ? `${theme.colors.error}` : 'transparent')};
  box-shadow: 1px 1px 40px 0 rgba(0, 0, 0, 0.02);
  background-color: ${({ theme }) => theme.colors.white};

  &:focus {
    outline: none;
    border: solid 1px ${({ theme }) => theme.colors.primary};
  }

  &:disabled {
    color: rgba(129, 142, 146, 0.4);
  }
`

const TextInput = props => <TextField type='text' name={props.id} {...props} />

export default TextInput
