import styled from 'styled-components'

const Sticky = styled.div`
  position: sticky;
  top: calc(140px + 45px + 18px);

  padding: 20px;

  border-radius: 20px;
  box-shadow: 1px 1px 40px 0 rgba(0, 0, 0, 0.02);
  border: solid 1px ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.white};
`

export default Sticky
