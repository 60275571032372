import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import { Formik } from 'formik'
import { useStore } from 'effector-react'

import media from 'utils/media'

import {
	store,
	editResykable,
	deleteResykable,
	updateImage,
	removeResykableImage,
	initializeImageUpload,
	uploadImage,
	finalizeImageUpload
} from 'components/common/GlobalState'
import { Row, Column } from 'components/common/Grid'
import Button from 'components/common/Button'
import BackLink from 'components/elements/BackLink'
import { Input, Label, Section, Title } from 'components/common/Form'

import linkIcon from 'assets/svg/link-white.svg'
import linkDisabledIcon from 'assets/svg/link-gray.svg'

const ImagesContainer = styled(Row)`
	margin-bottom: 50px;
`

const Buttons = styled.div`
	display: flex;
	flex-direction: column;
	button:first-child {
		margin-bottom: 20px;
	}
`

const ButtonCopyLink = styled(Button)`
	img {
		position: relative;
		top: 2px;
		margin-right: 15px;
	}
`

const ButtonDanger = styled.button`
	margin-top: 115px;

	font-size: 16px;
	font-weight: bold;
	color: ${({ theme }) => theme.colors.danger};

	${media.md`
    width: 300px;
  `}
`

const getDateString = iso_string => {
	if (!iso_string || iso_string === '0001-01-01T00:00:00') {
		return undefined
	}
	return iso_string.split('T')[0]
}

// Example guid: "c74382b2-eb9f-423f-a1a1-a4b9699694dc"
export default function ResykableEdit({ resykableGuid }) {
	const { resykables } = useStore(store)
	const { data } = resykables

	const [was_form_edited, set_was_form_edited] = React.useState()

	const [images, set_images] = React.useState([])
	const [visible_images, set_visible_images] = React.useState([])
	const [images_desc, set_images_desc] = React.useState([])
	const [deleted_images, set_deleted_images] = React.useState([])

	React.useEffect(() => {
		if (resykable && resykable.images) {
			set_images(resykable.images)
			set_images_desc(
				resykable.images.reduce(
					(acc, cur) => ({ ...acc, [`image-description-${cur.guid}`]: cur.description || '' }),
					{}
				)
			)
			set_visible_images(
				resykable.images.reduce((acc, cur) => ({ ...acc, [cur.guid]: cur.visibleInShowroom }), {})
			)
		}
	}, [])

	const deleteImage = imageId => {
		set_was_form_edited(true)
		const new_images = images.filter(({ guid }) => guid !== imageId)
		set_deleted_images([...deleted_images, imageId])
		set_images(new_images)
	}

	const resykable = data.filter(({ guid }) => guid === resykableGuid)[0]
	const initialValues = resykable && {
		title: resykable.title,
		description: resykable.description,
		isOnShow: resykable.isOnShow ? 'Display' : 'Hide',

		// purchaseDate: getDateString(resykable.purchaseDate),
		// purchasePrice: resykable.purchasePrice || '',
		// purchasedFrom: resykable.purchasedFrom || '',
		// purchaseState: resykable.purchaseState || '',

		'upload-images': [],
		...visible_images,
		...images_desc
	}

	const validate = values => {
		set_was_form_edited(true)
		let errors = {}

		errors.title = !values.title
		errors.description = !values.description || values.description.length > 500

		Object.keys(values).forEach(key => {
			if (key.includes('image-description-') && values[key]) {
				errors[key] = values[key].length > 255
			}
		})

		return Object.keys(errors)
			.filter(key => errors[key])
			.reduce((acc, key) => ((acc[key] = errors[key]), acc), {})
	}

	const onSubmit = async (values, { setSubmitting }) => {
		try {
			setSubmitting(true)
			const editResykableData = {
				resykableGuid,
				title: values.title,
				description: values.description,
				// purchasePrice: values.purchasePrice.toString(),
				// purchasedFrom: values.purchasedFrom,
				// purchaseState: values.purchaseState,
				isOnShow: values.isOnShow === 'Display'
			}

			// if(values.purchaseDate) {
			// 	const date = new Date(values.purchaseDate)
			// 	editResykableData.purchaseDate = date.toISOString()
			// }

			await editResykable(editResykableData)

			images.forEach(async image => {
				const imageGuid = image.guid
				const visibleInShowroom = values[imageGuid]
				const description = values[`image-description-${image.guid}`]
				await updateImage({ imageGuid, visibleInShowroom, description })
			})

			deleted_images.forEach(async imageId => {
				await removeResykableImage({ imageId, resykableGuid })
			})

			values['upload-images'].forEach(async file => {
				const { data } = await initializeImageUpload({ fileName: file.name })
				const { fileUploadUri, uploadSessionGuid } = data
				await uploadImage({ fileUploadUri, file })
				await finalizeImageUpload({ uploadSessionGuid, resykableGuid })
			})

			setSubmitting(false)
			navigate('/app/')
		} catch (error) {
			console.log({ error })
		}
	}

	const getError = (formik, name) => {
		const { errors, touched } = formik
		return errors[name] && touched[name] ? 'true' : undefined
	}

	const onCopyShowroomLinkClick = () => {
		const url = resykable && resykable.showroomUrl
		const el = document.createElement('textarea')
		el.value = url
		document.body.appendChild(el)
		el.select()
		document.execCommand('copy')
		document.body.removeChild(el)
		console.log(`Copied ${url} to clipboard`)
	}

	const onDeleteResykableClick = () => {
		deleteResykable({ resykableGuid })
		navigate('/app/')
	}

	return (
		<Section>
			<Row>
				<Column>
					<BackLink url='/app/' type='internal' title='Back' />
				</Column>
			</Row>
			<Row>
				<Column width={[1, 1, 2 / 3, 1 / 2]}>
					<Title>Manage Resykable</Title>
					<Formik
						initialValues={initialValues}
						validate={validate}
						onSubmit={onSubmit}
						enableReinitialize
					>
						{formik => (
							<form onSubmit={formik.handleSubmit}>
								{/* Title */}
								<Label htmlFor='title'>Title</Label>
								<Input.Text bold='true' id='title' error={getError(formik, 'title')} />

								{/* Description */}
								<Label htmlFor='description'>Description</Label>
								<Input.TextArea
									id='description'
									formik={formik}
									error={getError(formik, 'description')}
								/>

								{/* Images */}
								{!isEmpty(images) && (
									<>
										<Label htmlFor='images'>Images</Label>
										<ImagesContainer id='images'>
											{images.map(image => (
												<Column key={image.guid} width={[1, 1 / 2]}>
													<Input.Image image={image} formik={formik} deleteImage={deleteImage} />
													<Label
														small='true'
														dimmed={!formik.values[image.guid] ? 'true' : undefined}
														htmlFor={`image-description-${image.guid}`}
													>
														Image description
													</Label>
													<Input.Text
														bold='true'
														dimmed={!formik.values[image.guid] ? 'true' : undefined}
														id={`image-description-${image.guid}`}
														formik={formik}
														error={getError(formik, `image-description-${image.guid}`)}
													/>
												</Column>
											))}
										</ImagesContainer>
									</>
								)}

								{/* Upload Images */}
								<Label htmlFor='upload-images'>Upload new image</Label>
								<Input.Images
									id='upload-images'
									formik={formik}
									error={getError(formik, 'upload-images')}
								/>

								{
									// {/* Purchase Date */}
									// <Label htmlFor='purchaseDate'>
									// 	Purchase Date <span>Optional</span>
									// </Label>
									// <Input.Date
									// 	id='purchaseDate'
									// 	formik={formik}
									// 	error={getError(formik, 'purchaseDate')}
									// />
									// {/* Purchase Price */}
									// <Label htmlFor='purchasePrice'>
									// 	Purchase Price <span>Optional</span>
									// </Label>
									// <Input.Number
									// 	id='purchasePrice'
									// 	formik={formik}
									// 	error={getError(formik, 'purchasePrice')}
									// 	step='0.01'
									// />
									// {/* Purchased From */}
									// <Label htmlFor='purchasedFrom'>
									// 	Purchased From <span>Optional</span>
									// </Label>
									// <Input.Text
									// 	id='purchasedFrom'
									// 	formik={formik}
									// 	error={getError(formik, 'purchasedFrom')}
									// />
									// {/* Purchase State */}
									// <Label htmlFor='purchaseState'>
									// 	Purchase State <span>Optional</span>
									// </Label>
									// <Input.Radio
									// 	id='purchaseState'
									// 	value={formik.values['purchaseState']}
									// 	error={getError(formik, 'purchaseState')}
									// 	options={['New', 'Used', 'Other']}
									// />
								}

								{/* Display in showroom */}
								<Label htmlFor='isOnShow'>Display in showroom</Label>
								<Input.Radio
									id='isOnShow'
									value={formik.values['isOnShow']}
									error={getError(formik, 'isOnShow')}
									options={['Display', 'Hide']}
								/>

								<Buttons>
									<Button primary type='submit' disabled={formik.isSubmitting || !was_form_edited}>
										Save changes
									</Button>

									<ButtonCopyLink
										primary
										type='button'
										onClick={onCopyShowroomLinkClick}
										disabled={!(resykable && resykable.showroomUrl)}
									>
										<img
											src={resykable && resykable.showroomUrl ? linkIcon : linkDisabledIcon}
											alt='Copy Showroom Link'
										/>
										Copy Showroom Link
									</ButtonCopyLink>

									<ButtonDanger type='button' onClick={onDeleteResykableClick}>
										Delete Resykable
									</ButtonDanger>
								</Buttons>
							</form>
						)}
					</Formik>
				</Column>
			</Row>
		</Section>
	)
}
