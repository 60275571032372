import React, { useEffect } from 'react'
import { useStore } from 'effector-react'
import { Router } from '@reach/router'
import isEmpty from 'lodash/isEmpty'

import Layout from 'components/common/Layout'
import Spinner from 'components/common/Spinner'

import Welcome from 'components/Welcome'
import Registry from 'components/Registry'

import ResykableCreate from 'components/ResykableCreate'
import ResykableEdit from 'components/ResykableEdit'
import Profile from 'components/Profile'
import ProfileEdit from 'components/ProfileEdit'

import { store, getUserDetails, getResykables } from 'components/common/GlobalState'

import AuthService from 'services/auth'
import useAuth from 'utils/hooks/useAuth'

const Home = ({ resykables }) =>
	!isEmpty(resykables) ? <Registry resykables={resykables} /> : <Welcome />

export default function Homepage({ location }) {
	const { profile = {} } = useAuth()
	const {
		profile: storedProfile,
		resykables: { data, isLoading },
		auth: { accessToken }
	} = useStore(store)

	useEffect(() => {
		if (isEmpty(profile)) {
			console.log('NOT LOGED')
			return AuthService.login()
		}

		if (isEmpty(storedProfile.displayName)) {
			getUserDetails(accessToken)
		}

		if (isEmpty(data)) {
			getResykables(accessToken)
		}

		console.log(`Hello ${profile.name}!`)
	}, [])

	if (storedProfile.isLoading || isLoading || data === undefined) {
		return <Spinner />
	}

	return (
		<Layout pageTitle='Application' location={location}>
			<Router>
				<Home path='/app' resykables={data} />

				<ResykableCreate path='/app/resykable/create/' />
				<ResykableEdit path='/app/resykable/:resykableGuid/edit' />

				<Profile path='/app/profile' />
				<ProfileEdit path='/app/profile/edit' />
			</Router>
		</Layout>
	)
}
