import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'
import delve from 'dlv'

import Button from 'components/common/Button'
import Modal, { ModalText } from 'components/common/Modal'

import deleteIcon from 'assets/svg/x-white.svg'

const ImageContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

const Switch = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: 20px 0;
`

const Option = styled.div`
	font-size: 13px;
	font-weight: ${({ bold }) => bold && 'bold'};
	color: ${({ theme }) => theme.colors.gray};
	cursor: pointer;

	& + div {
		margin-left: 10px;
	}
`

const ImagePreview = styled.div`
	position: relative;
	width: 100%;
	object-fit: cover;
	border-radius: 10px;
	opacity: ${({ visible }) => (visible ? 1 : 0.4)};
	transition: opacity 0.2s;
	overflow: hidden;

	img {
		display: block;
	}

	span {
		position: absolute;
		top: 0;
		left: 0;
		height: 40px;
		width: 40px;
		background-color: ${({ theme }) => theme.colors.danger};

		&::after {
			content: '';
			position: absolute;
			width: 40px;
			height: 40px;

			background-image: url(${deleteIcon});
			background-repeat: no-repeat;
			background-position: 10px;
			background-size: 20px;
			cursor: pointer;
		}
	}
`

const Image = ({ image, formik, deleteImage }) => {
	const id = image.guid
	const is_visible = formik.values[id]

	const [is_modal_open, set_is_modal_open] = React.useState()

	const openModal = () => set_is_modal_open(true)
	const closeModal = () => set_is_modal_open(false)
	const confirmModal = () => {
		deleteImage(id)
		set_is_modal_open(false)
	}

	const show = () => formik.setFieldValue(id, true)
	const hide = () => formik.setFieldValue(id, false)

	const getImgSrc = image => {
		if (delve(image, 'kraks.1.url')) {
			return image.kraks[1].url
		} else {
			return image.kraks[0].url
		}
	}

	return (
		<>
			<Modal isOpen={is_modal_open} closeModal={closeModal}>
				<ModalText>Are you sure you want to delete this image?</ModalText>
				<Button primary type='button' onClick={confirmModal}>
					Yes
				</Button>
				<Button primary border type='button' onClick={closeModal}>
					Cancel
				</Button>
			</Modal>

			<ImageContainer>
				<Switch>
					<Field id={id} type='checkbox' hidden />
					<Option onClick={show} bold={is_visible ? 'true' : undefined}>
						Show
					</Option>
					<Option onClick={hide} bold={!is_visible ? 'true' : undefined}>
						Hide
					</Option>
				</Switch>
				<ImagePreview visible={is_visible}>
					<img src={getImgSrc(image)} alt={image.guid} />
					<span onClick={openModal} />
				</ImagePreview>
			</ImageContainer>
		</>
	)
}

export default Image
