import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { useStore } from 'effector-react'

import { getAddResykableEmailData } from 'utils/emails'

import { Container, Row, Column } from 'components/common/Grid'
import { store } from 'components/common/GlobalState'
import Link from 'components/elements/Link'
import Modal from './Modal'

import media from 'utils/media'

import plusSvg from 'assets/svg/plus-circle-green.svg'
import envelopeSvg from 'assets/svg/envelope-blob.svg'
import uploaderSvg from 'assets/svg/uploader-blob.svg'

const Section = styled.section`
	padding-top: 50px;
	padding-bottom: 50px;

	${media.md`
    padding-top: 130px;
    padding-bottom: 80px;
  `}
`

const WelcomeTitle = styled.h3`
	margin-bottom: 25px;
	font-size: 30px;
	font-family: QuickSand;
	font-weight: bold;
	color: ${({ theme }) => theme.colors.primary};
	text-align: center;

	${media.md`
    font-size: 35px;
  `}
`

const WelcomeDescription = styled.p`
	margin-bottom: 25px;
	font-size: 15px;
	font-weight: 300;
	line-height: 28px;
	color: ${({ theme }) => theme.colors.gray};
	text-align: center;

	${media.md`
    font-size: 20px;
    width: 70%;
    margin: 0 auto 80px auto;
  `}
`

const Tiles = styled(Row)`
	/* flex-direction: column-reverse; */
	/* margin-bottom: 40px; */

	${media.sm`
    /* flex-direction: row; */
  `}
`

const Tile = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	cursor: pointer;
	/* margin-bottom: 20px; */

	border-radius: 25px;
	box-shadow: 1px 1px 40px 0 rgba(0, 0, 0, 0.02);
	background-color: ${({ theme }) => theme.colors.white};

	${media.md`
    padding:  50px;
  `}

	p {
		color: ${({ theme }) => theme.colors.primary};
		font-weight: 300;
		font-family: QuickSand;
		font-size: 15px;
		line-height: 20px;

		span {
			font-weight: bold;
		}
	}

	a {
		display: flex;
		align-items: center;
	}
`

const TileText = styled.div`
	display: flex;
	align-items: center;

	p {
		margin-left: 20px;
		font-size: 15px;
		line-height: 1.2;

		${media.md`
      margin-left: 30px;
      font-size: 20px;
    `}
	}
`

const Icon = styled.img`
	width: 100px;
	margin-bottom: 20px;
`

const Buttons = styled.div`
	display: flex;
	flex-direction: column;

	& > button:not(:last-child) {
		margin-bottom: 20px;
	}
`

export default function Welcome() {
	const [is_modal_open, set_is_modal_open] = React.useState()
	const { profile: userDetails } = useStore(store)

	const openModal = () => set_is_modal_open(true)
	const closeModal = () => set_is_modal_open(false)

	const openEmailClient = () => {
		if (userDetails && userDetails.resykEmail) {
			openModal()
			window.location = getAddResykableEmailData(userDetails.resykEmail)
		}
	}

	return (
		<Section>
			<Container>
				<Row>
					<Column>
						<Modal isOpen={is_modal_open} closeModal={closeModal} />

						<WelcomeTitle>Welcome to Resyk!</WelcomeTitle>

						<WelcomeDescription className='lead'>
							The FREE online filing service and a community that cares about creating a circular
							economy with the things we buy.
						</WelcomeDescription>
					</Column>
				</Row>

				<Tiles>
					<Column width={[1, 1, 1 / 2]} pt={[16, 16, 0]} order={[2, 2, 1]}>
						<Tile onClick={openEmailClient}>
							<Icon src={envelopeSvg} alt='Envelope icon' />

							<TileText>
								<button>
									<img src={plusSvg} alt='Add button' />
								</button>
								<p>
									Add your first Resykable <br /> <span>using the email uploader</span>
								</p>
							</TileText>
						</Tile>
					</Column>

					<Column width={[1, 1, 1 / 2]} order={[1, 1, 2]}>
						<Link url='/app/resykable/create/' type='internal' state={{ showFileDialog: true }}>
							<Tile>
								<Icon src={uploaderSvg} alt='Upload icon' />

								<TileText>
									<img src={plusSvg} alt='Add button' />

									<p>
										Add your first Resykable <br /> <span>using the web uploader</span>
									</p>
								</TileText>
							</Tile>
						</Link>
					</Column>
				</Tiles>

				{/* Profile editing won’t be available in the first release */}
				{/* 
              <Buttons>
                <Button primary onClick={goToEditProfile}>
                  Complete Your Profile
                </Button>
              </Buttons> 
            */}
			</Container>
		</Section>
	)
}
