import styled from 'styled-components'

const Title = styled.h2`
  margin: 30px 0;

  font-family: QuickSand;
  font-size: 30px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
`

export default Title
