import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import delve from 'dlv'

import { Container, Column, Row } from 'components/common/Grid'
import Button from 'components/common/Button'

import placeholderImg from 'assets/img/placeholder.png'

const ContainerStyled = styled(Container)`
	margin-top: 50px;
`

const Title = styled.h1`
	font-family: QuickSand;
	font-size: 27px;
	font-weight: bold;
	color: ${({ theme }) => theme.colors.primary};
	text-align: center;
`

const Tabs = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 45px 0;
`

const Tab = styled.button`
	font-size: 15px;
	font-family: QuickSand;
	font-weight: bold;
	line-height: 1.47;
	padding-bottom: 10px;

	color: ${({ theme, active }) => (active ? theme.colors.black : theme.colors.silver)};

	border-bottom: 3px solid
		${({ theme, active }) => (active ? theme.colors.primary : theme.colors.silver)};
`

const Card = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-bottom: 50px;
	background-color: #fff;
	border-radius: 15px;

	${({ inactive }) => inactive && 'opacity: 0.3'};
`

const CardImage = styled.div`
	width: 100%;
	height: 190px;

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
	}
`

const CardContent = styled.div`
	display: flex;
	flex-direction: column;
	padding: 30px;
	width: 100%;

	h5 {
		font-size: 24px;
		color: ${({ theme }) => theme.colors.primary};
	}
`

const CardDetails = styled.div`
	display: flex;
	align-items: center;
	margin: 15px 0;

	p {
		font-size: 15px;
		line-height: 21px;
		display: flex;
		flex-direction: column;

		span:first-child {
			font-weight: bold;
		}
	}
`

const CardButton = styled(Button)`
	font-size: 15px;
	max-width: 100%;
	padding: 13px;
	margin: auto;
`

const getDate = dateUTC => {
	if (dateUTC) {
		const date = new Date(dateUTC)
		const day = date.getDate()
		const month = date.getMonth() + 1
		const year = date.getFullYear()
		return `${day}/${month}/${year}`
	}
}

const Registry = ({ resykables }) => {
	const [tab, setTab] = React.useState(0)

	const setCurrentResykablesTab = () => setTab(0)
	const setInactiveResykablesTab = () => setTab(1)

	const goToResykableEdit = resykable_guid => () => {
		navigate(`/app/resykable/${resykable_guid}/edit`)
	}

	const getImgSrc = image => {
		if (delve(image, 'kraks.1.url')) {
			return image.kraks[1].url
		} else {
			return image.kraks[0].url
		}
	}

	return (
		<ContainerStyled>
			<Row>
				<Column>
					<Title>My Registry</Title>
					<Tabs>
						<Tab type='button' onClick={setCurrentResykablesTab} active={tab === 0}>
							Current Resykables
						</Tab>
						{/* <Tab
                type='button'
                onClick={setInactiveResykablesTab}
                active={tab === 1}
              >
                Inactive Resykables
              </Tab> */}
					</Tabs>
				</Column>
			</Row>

			<Row>
				{tab === 0 &&
					!isEmpty(resykables) &&
					resykables.map(resykable => (
						<Column key={resykable.guid} width={[1, 1 / 2, 1 / 2, 1 / 3]}>
							<Card>
								<CardImage>
									{resykable.images[0] ? (
										<img src={getImgSrc(resykable.images[0])} alt={resykable.images[0].guid} />
									) : (
										<img src={placeholderImg} alt='resykable-img' />
									)}
								</CardImage>

								<CardContent>
									<h5>{resykable.title}</h5>
									<CardDetails>
										<p>
											<span>Added</span>
											<span>{getDate(resykable.dateAddedUtc) || 'Unknown'}</span>
										</p>
									</CardDetails>
									<CardButton primary onClick={goToResykableEdit(resykable.guid)}>
										Manage Resykable
									</CardButton>
								</CardContent>
							</Card>
						</Column>
					))}
			</Row>
		</ContainerStyled>
	)
}

export default Registry
