import styled, {css} from 'styled-components'

import squiggleImg from 'assets/svg/squiggle.svg'

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;

  font-family: WorkSans;
 
  text-align: left;
  color: ${({ theme }) => theme.colors.gray};
  opacity: ${({ dimmed }) => dimmed && '0.5'};

  span {
    margin-left: 15px;

    font-size: 13px;
    font-weight: bold;
    line-height: 1.15;
  }

  ${({small}) => small ? css`
      margin-top: 25px;

      font-size: 16px;
      font-weight: bold;
      line-height: 2.5;
    ` : css`
      margin-bottom: 10px;

      font-size: 20px;
      font-weight: 300;
      line-height: 1.2;

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100px;
        height: 10px;

        background-image: url(${squiggleImg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 0;
      }
  `}
`

export default Label
