import styled from 'styled-components'

import media from 'utils/media'

import { Sticky } from 'components/common/Form'

import envelopeSvg from 'assets/svg/envelope.svg'
import questionMarkPng from 'assets/img/question-mark.png'

const InfoStyled = styled(Sticky)`
  display: none;

  ${media.md`
    display: block;
  `}

  h4 {
    padding: 15px 0;
    font-family: WorkSans;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.gray};
  }

  p {
    font-family: WorkSans;
    font-size: 15px;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.gray};
    a {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: bold;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-family: QuickSand;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.59;
      color: ${({ theme }) => theme.colors.primary};
    }

    & > div {
      display: flex;
      align-items: center;
    }

    img {
      width: 60px;
      height: 60px;
    }
  }
`

import React from 'react'

const Info = ({ userDetails }) => (
  <InfoStyled>
    <div>
      <div>
        <img src={questionMarkPng} alt='question-mark-icon' />
        <h3>Did you know...</h3>
      </div>
      <img src={envelopeSvg} alt='envelope-icon' />
    </div>

    <h4>
      You can also upload a Resykable using your unique Resyk email address?
    </h4>

    <p>
      All you need to do is email your images and description, using the subject
      as your Resykable title to{' '}
      <a href={`mailto:${userDetails.resykEmail}`}>{userDetails.resykEmail}</a>{' '}
      and it will automatically add to your list of Resykables.
    </p>
  </InfoStyled>
)

export default Info
