/* Form Inputs */
import Date from './Inputs/Date'
import Image from './Inputs/Image'
import Images from './Inputs/Images'
import Number from './Inputs/Number'
import Radio from './Inputs/Radio'
import SingleImage from './Inputs/SingleImage'
import Switch from './Inputs/Switch'
import Text from './Inputs/Text'
import TextArea from './Inputs/TextArea'

/* Other */
import Label from './Label'
import Section from './Section'
import Sticky from './Sticky'
import Title from './Title'

const Input = {
  Date,
  Number,
  Image,
  Images,
  Radio,
  SingleImage,
  Switch,
  Text,
  TextArea
}

export { Input, Label, Section, Sticky, Title }
