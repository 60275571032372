import styled from 'styled-components'

import Link from '../PromptLink'
import arrowLeft from 'assets/svg/arrow-left.svg'

const BackLink = styled(Link)`
  display: block;
  position: relative;
  margin-left: 12.5px;

  font-family: QuickSand;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.gray};

  &::before {
    content: '';
    position: absolute;
    top: 3.5px;
    left: -12.5px;
    width: 6px;
    height: 9px;
    background-image: url(${arrowLeft});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
  }
`

export default BackLink
