import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'

import media from 'utils/media'

const RadioContainer = styled.div`
  margin: 40px 0;
  font-weight: bold;

  ${media.md`
    display: flex;
  `}

  label {
    display: block;
    position: relative;
    padding-left: 60px;
    margin-bottom: 10px;

    font-family: WorkSans;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.gray};

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;

    ${media.md`
      padding: 0 35px;
    `}
  }

  label input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
    cursor: pointer;
  }

  span {
    position: absolute;
    top: -2px;
    left: 0;
    height: 25px;
    width: 25px;
    border: 2px solid
      ${({ theme, error }) =>
        error ? theme.colors.error : theme.colors.primary};
    border-radius: 50%;
  }

  span:after {
    content: '';
    position: absolute;
    display: none;
  }

  label input:checked ~ span:after {
    display: block;
  }

  label:hover input ~ span {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  label span:after {
    top: calc((25px - 15px) / 2 - 2px);
    left: calc((25px - 15px) / 2 - 2px);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.primary};
  }
`

const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}) => (
  <label>
    {label}
    <input
      name={name}
      id={id}
      type='radio'
      value={id}
      checked={id === value}
      onChange={onChange}
      onBlur={onBlur}
      {...props}
    />
    <span />
  </label>
)

const Radio = ({ id, error, options }) => (
  <RadioContainer error={error}>
    {options.map(option => (
      <Field
        key={option}
        component={RadioButton}
        name={id}
        id={option}
        label={option}
      />
    ))}
  </RadioContainer>
)

export default Radio
