import React from 'react'
import styled from 'styled-components'

import TextField from './Text'

const NrField = styled(TextField)``

const NrInput = props => <NrField type='number' name={props.id} {...props} />

export default NrInput
